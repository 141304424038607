<template>
  <main>
    <package-header
      title="Applications"
      sub-title="12764 Smith"
      status="Defected"
      :showEsign="false"
      actionLabel="Download Package"
      actionButtonClass="secondary"
      :showActionIcon="false"
    ></package-header>

    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-2">
          <!-- Package navigation hamburger -->
          <button
            class="btn btn-secondary d-lg-none mb-3"
            data-toggle="collapse"
            data-target="#package-nav"
            aria-expanded="false"
            aria-controls="package-nav"
          >
            <svg class="icon icon-menu">
              <use xlink:href="/icons/symbol-defs.svg#icon-menu"></use>
            </svg>
            <svg class="icon icon-close">
              <use xlink:href="/icons/symbol-defs.svg#icon-close"></use>
            </svg>
            Navigate Package
          </button>

          <div class="collapse collapse-lg" id="package-nav">
            <package-navigation></package-navigation>
          </div>
        </div>

        <div class="col">
          <div class="d-flex justify-content-between align-items-end pb-2">
            <!-- Table toolbar -->
            <div>
              <div class="btn-toolbar sticky-top" v-show="checked > 0">
                <div class="btn-group">
                  <svg class="icon icon-corner-left-down">
                    <use
                      xlink:href="/icons/symbol-defs.svg#icon-corner-left-down"
                    ></use>
                  </svg>
                </div>

                <div class="btn-group" role="group">
                  <button type="button" class="btn btn-md btn-secondary">
                    Download
                  </button>
                  <button type="button" class="btn btn-md btn-secondary">
                    Print
                  </button>
                  <button type="button" class="btn btn-md btn-danger">
                    Delete
                  </button>
                </div>
              </div>
            </div>

            <router-link
              class="btn btn-md btn-secondary"
              to="/pages/add-applications"
              >Withdraw Application</router-link
            >
          </div>

          <!-- Package Management table -->
          <div class="sticky-table">
            <table class="table table-hover table-stacked table-select-row">
              <thead class="bg-medium">
                <tr>
                  <th scope="row">
                    <input type="checkbox" id="th" />
                  </th>
                  <th scope="col">Name</th>
                  <th scope="col">Type</th>
                  <th scope="col">Document #.</th>
                  <th scope="col">Status</th>
                  <th scope="col">Modified</th>
                  <th scope="col">Transparency Declaration ID#</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(item, index) in tableItems">
                  <tr>
                    <td scope="row">
                      <input
                        type="checkbox"
                        :id="index"
                        @click="checkbox($event)"
                      />
                    </td>
                    <td data-header="Name">
                      <span class="td-content"
                        ><a href="#">{{ item.b }}</a></span
                      >
                    </td>
                    <td data-header="Document No.">
                      <span class="td-content">{{ item.c }}</span>
                    </td>
                    <td data-header="Application">
                      <span class="td-content">{{ item.f }}</span>
                    </td>
                    <td data-header="Modified">
                      <span class="td-content">{{ item.d }}</span>
                    </td>
                    <td data-header="Status" v-html="item.e"></td>
                    <td data-header="Actions">
                      <span class="td-content"
                        ><router-link to="/pages/electronic-instrument"
                          >View Instrument</router-link
                        ></span
                      >
                    </td>
                    <td>Actions</td>
                  </tr>
                  <tr v-if="item.e === 'Defected'" :key="index">
                    <td class="border-top-0"></td>
                    <td colspan="7" class="border-top-0">
                      <div class="mb-2">
                        <button class="btn btn-secondary btn-md">
                          Start Correction
                        </button>
                        <small class="ml-2">
                          File Correction by: Jan 9, 2025</small
                        >
                      </div>
                      <a href="javascript:void;"
                        >See Defect Notice in Notifications</a
                      >
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>

          <!-- Table help -->
          <button
            class="btn collapse-btn collapsed"
            data-toggle="collapse"
            data-target="#application-help"
            aria-expanded="false"
            aria-controls="application-help"
          >
            <svg class="icon icon-arrow_right collapse-icon">
              <use xlink:href="/icons/symbol-defs.svg#icon-arrow_right"></use>
            </svg>
            How to use this table
          </button>
          <div
            id="application-help"
            class="collapse"
            aria-labelledby="headingOne"
            data-parent="#application-help"
          >
            <div class="collapse-body">Collapse content</div>
          </div>
        </div>
      </div>

      <!-- Scroll to top -->
      <a href="#top" class="d-block float-right d-print-none scroll-to-top">
        <svg class="icon icon-arrow_upward">
          <use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"></use>
        </svg>
        Back to Top
      </a>
    </div>

    <help-panel title="Package Management"></help-panel>
  </main>
</template>

<script>
import PackageHeader from '../../components/PackageHeader.vue';
import PackageNavigation from '../../components/PackageNavigation.vue';
import HelpPanel from '../../components/HelpPanel.vue';

export default {
  components: {
    'package-header': PackageHeader,
    'package-navigation': PackageNavigation,
    'help-panel': HelpPanel,
  },

  data() {
    return {
      checked: 0,
      sequenced: false,
      tableItems: [
        {
          a: '1',
          b: 'Form-A-Anderson',
          c: 'Form A (Freehold Transfer)',
          d: 'TX6075262',
          e: 'Defected',
          f: 'Dec 2, 2024',
          g: 'L-001-686-237-TD',
        },
        {
          a: '2',
          b: 'A2 release',
          c: 'Property Transfer Tax Return',
          d: '',
          e: 'Accepted',
          f: 'Dec 2, 2024',
          g: '',
        },
        {
          a: '3',
          b: 'Mortgage Caso',
          c: 'Transparency Declaration',
          d: '',
          e: 'Filed',
          f: 'Dec 1, 2024',
          g: '',
        },
        {
          a: '4',
          b: 'Mortgage Caso',
          c: 'Form B (Mortgage)',
          d: 'CA748835',
          e: 'Defected',
          f: 'Dec 1, 2024',
          g: '',
        },
      ],
    };
  },
  methods: {
    checkbox: function ($event) {
      var _this = this;

      if (event.target.checked) {
        _this.checked++;
      } else {
        _this.checked--;
      }
    },
  },
};
</script>

<!-- <span class="td-content">Defected <span class="badge badge-pill badge-danger">&nbsp;</span></span> -->

<!-- <button class="d-block btn btn-tertiary btn-sm mt-1">
                      Correct Application
                    </button> -->
